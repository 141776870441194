import React from 'react'
import { Link } from 'gatsby'
import styles from './footerLinks.module.scss'

export default ({ data }) => (
  <nav className={styles.links}>
    {data.map((link, key) => (
      <Link key={key} to={link.url[0] == '/' ? link.url : '/'+link.url} className={styles.item}>
        {link.title}
      </Link>
    ))}
  </nav>
)
