import React from 'react'
import { Link } from 'gatsby'
import sizes from './button-sizes.module.scss'
import variants from './button-variants.module.scss'

export default ({ children, size = 'default', variant, ...props }) => {
  const constCheckIfLinkIsInternal = props.href && /^\/(?!\/)/.test(props.href)
  const className = `${(size && sizes[size]) || 'default'} ${(variant &&
    variants[variant]) ||
    ''}`

  const Button = () => (
    <button className={className} {...props}>
      {children}
    </button>
  )
  const DefaultLink = () => (
    <a className={className} {...props}>
      {children}
    </a>
  )
  const GatsbyLink = () => (
    <Link to={props.href} className={className} {...props}>
      {children}
    </Link>
  )

  return props.href ? (
    constCheckIfLinkIsInternal ? (
      <GatsbyLink />
    ) : (
      <DefaultLink />
    )
  ) : (
    <Button />
  )
}
