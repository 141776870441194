import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

const Seo = ({
  noIndex,
  title,
  description,
  image,
  globalSeo,
  faviconMetaTags,
  canonical,
}) => {
  const resultTitle = `${title || globalSeo.fallbackSeo.title} ${
    globalSeo.titleSuffix
  }`
  const resultddescription = description || globalSeo.fallbackSeo.description
  const resultImage = image || globalSeo.fallbackSeo.image

  return (
    <Helmet>
      <html lang="en" />
      <meta
        content={noIndex ? 'noindex,nofollow' : 'index,follow'}
        name="robots"
      />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, minimal-ui, viewport-fit=cover"
      />
      <title>{resultTitle}</title>
      <meta name="description" content={resultddescription} />
      <meta property="og:title" content={resultTitle} />
      <meta property="og:description" content={resultddescription} />
      <meta property="og:site_name" content={globalSeo.siteName} />
      {resultImage && <meta property="og:image" content={resultImage.url} />}
      {resultImage && (
        <meta property="og:image:width" content={resultImage.width} />
      )}
      {resultImage && (
        <meta property="og:image:height" content={resultImage.height} />
      )}
      <meta property="og:locale" content="en" />
      {resultImage && (
        <meta
          property="og:image:type"
          content={`image/${resultImage.format}`}
        />
      )}

      {canonical && <link rel="canonical" href={canonical}></link>}

      {faviconMetaTags &&
        faviconMetaTags.tags.map(({ attributes, tagName }, key) =>
          tagName === 'link' ? (
            <link
              type={attributes.type}
              rel={attributes.rel}
              href={attributes.href}
              sizes={attributes.sizes}
              key={key}
            />
          ) : (
            <meta
              property={attributes.name}
              content={attributes.content}
              key={key}
            />
          )
        )}
    </Helmet>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        datoCmsSite {
          globalSeo {
            titleSuffix
            fallbackSeo {
              title
              description
              image {
                url
                width
                height
                format
              }
            }
            siteName
          }
          noIndex
          faviconMetaTags {
            tags
          }
        }
      }
    `}
    render={data => <Seo {...data.datoCmsSite} {...props} />}
  />
)
