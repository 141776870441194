import { Link } from 'gatsby';
import React from 'react';

export default ({ children, to, ...rest }) => {
  const checkIfLinkIsInternal = to && /^\/(?!\/)/.test(to)
  const updatedTo = to && to.substr(-1) !== '/' ? `${to}/` : to;

  const Button = () => <button {...rest}>{children}</button>
  const DefaultLink = () => (
    <a {...rest} href={updatedTo}>
      {children}
    </a>
  )
  const GatsbyLink = () => (
    <Link {...rest} to={updatedTo}>
      {children}
    </Link>
  )

  return to ? (
    checkIfLinkIsInternal ? (
      <GatsbyLink />
    ) : (
      <DefaultLink />
    )
  ) : (
    <Button />
  )
}
