import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import styles from './pageHeader.module.scss'
import Container from './container'
import Link from './link'

import IconHamburger from '../images/hamburger.js'
import IconClose from '../images/close.js'
import { Helmet } from 'react-helmet'
import auth0 from 'auth0-js'

class Header extends React.Component {
  state = { mobileMenuIsOpen: false }

  handleTriggerMenu() {
    this.setState({ mobileMenuIsOpen: !this.state.mobileMenuIsOpen })
    //prevent for scroll in mobile devices
    !this.state.mobileMenuIsOpen
      ? document.body.classList.add('modal-is-open')
      : document.body.classList.remove('modal-is-open')
  }

  componentDidMount() {
    if (!this.state.mobileMenuIsOpen) {
      document.body.classList.remove('modal-is-open')
    }
  }

  auth0 = new auth0.WebAuth({
    domain: process.env.GATSBY_AUTH0_DOMAIN,
    clientID: process.env.GATSBY_AUTH0_CLIENT_ID,
    redirectUri: process.env.GATSBY_AUTH0_REDIRECT_URI,
    responseType: 'token id_token',
    scope:
      'openid profile email user_id id read:current_user update:current_user_metadata',
  })

  createCookie = (name, values, domain) => {
    // Set cookie expiration date to one year from now
    var expires = new Date()
    expires.setHours(1)

    // Create the cookie string
    var cookieString = name + '=' + encodeURIComponent(JSON.stringify(values))

    // Add the domain if provided
    if (domain) {
      cookieString += '; domain=' + domain
    }

    // Set the cookie
    document.cookie = cookieString
  }

  generateRandomString = () => {
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var length = 9
    var randomString = ''

    for (var i = 0; i < length; i++) {
      var randomIndex = Math.floor(Math.random() * characters.length)
      randomString += characters.charAt(randomIndex)
    }

    return randomString
  }

  handleSignUp = () => {
    const state = this.generateRandomString()
    const nonce = this.generateRandomString()

    this.createCookie('stateCreds', { state, nonce }, '.gymradio.com')

    let config = {
      screen_hint: 'signup',
      nonce,
      state,
    }

    this.auth0.authorize(config)
  }

  handleLogin = () => {
    const state = this.generateRandomString()
    const nonce = this.generateRandomString()

    this.createCookie('stateCreds', { state, nonce }, '.gymradio.com')

    let config = {
      nonce,
      state,
    }

    this.auth0.authorize(config)
  }

  render() {
    const { data, currentUrl } = this.props
    const menuIsOpen = this.state.mobileMenuIsOpen

    const Logo = () => (
      <img
        src={data.datoCmsPageHeader.logo.url}
        alt={data.datoCmsSite.name}
        className={styles.logo}
      />
    )

    return (
      <header className={styles.header}>
        <Helmet>
          <script
            type="text/javascript"
            id="hs-script-loader"
            async
            defer
            src="//js-eu1.hs-scripts.com/139640924.js"
          ></script>
        </Helmet>
        <Container variant="navbar">
          {currentUrl === 'home' ? (
            <figure>
              <Logo />
            </figure>
          ) : (
            <Link to="/">
              <Logo />
            </Link>
          )}

          <a
            // href={data.datoCmsPageHeader.buttonLinkSignup}
            className={styles.signup}
            onClick={() => this.handleSignUp()}
          >
            {data.datoCmsPageHeader.buttonTextSignup}
          </a>

          <nav
            className={`${styles.navigation} font-secondary`}
            data-opened={menuIsOpen}
            id="navigation"
          >
            <Link to="/">Home</Link>
            {data.datoCmsPageHeader.navigationLinks.map((link) => (
              <Link
                key={link.id}
                to={`/${link.url}`}
                className={(currentUrl === link.url && 'active') || null}
              >
                {link.title}
              </Link>
            ))}
          </nav>
          <a
            //  href={data.datoCmsPageHeader.buttonLink}
            className={styles.login}
            onClick={() => this.handleLogin()}
          >
            {data.datoCmsPageHeader.buttonText}
          </a>
          <button
            type="button"
            aria-expanded={menuIsOpen}
            aria-controls="navigation"
            className={styles.hamburger}
            onClick={() => this.handleTriggerMenu()}
          >
            {menuIsOpen ? <IconClose /> : <IconHamburger />}
          </button>
        </Container>
      </header>
    )
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        datoCmsPageHeader {
          logo {
            url
          }
          buttonText
          buttonLink

          buttonTextSignup
          buttonLinkSignup

          navigationLinks {
            id
            title
            url
          }
        }
        datoCmsSite {
          name
        }
      }
    `}
    render={(data) => <Header data={data} {...props} />}
  />
)
