export default ({ image, sizes, srcSet, customOptions = '', quality = 85 }) => {
  const currentData = image.fluid || image.fixed

  const fluid = () => {
    const srcSize = () => {
      const lastInArray = srcSet[srcSet.length - 1]
      return typeof lastInArray === 'string'
        ? `w=${lastInArray}`
        : `w=${lastInArray.w}&h=${lastInArray.h}&fit=crop`
    }
    return {
      ...currentData,
      src: `${image.url}?${srcSize()}&lossless=0&q=${quality}${customOptions}`,
      srcSet: srcSet
        .map(s => {
          if (typeof s === 'string') {
            return `${
              image.url
            }?w=${s}&lossless=0&q=${quality}${customOptions} ${s}w`
          } else {
            return `${image.url}?w=${s.w}&h=${
              s.h
            }&lossless=0&q=${quality}&fit=crop${customOptions} ${s.w}w`
          }
        })
        .toString(),
      sizes: `(min-width: ${960 / 16}em) ${sizes.desktop}, (min-width: ${600 /
        16}em) ${sizes.tablet}, ${sizes.mobile}`,

      srcWebp: `${
        image.url
      }?${srcSize()}&fm=webp&lossless=0&q=${quality}${customOptions}`,
      srcSetWebp: srcSet
        .map(s => {
          if (typeof s === 'string') {
            return `${
              image.url
            }?w=${s}&lossless=0&q=${quality}&fm=webp${customOptions} ${s}w`
          } else {
            return `${image.url}?w=${s.w}&h=${
              s.h
            }&lossless=0&q=${quality}&fm=webp&fit=crop${customOptions} ${s.w}w`
          }
        })
        .toString(),
    }
  }

  const fixed = () => {
    return {
      ...currentData,
      src: `${image.url}?w=${image.fixed.width}&lossless=0&q=${quality}`,
      srcSet: `${image.url}?w=${
        image.fixed.width
      }&lossless=0&q=${quality} 1x, ${image.url}?w=${image.fixed.width *
        1.5}&lossless=0&q=${quality} 1.5x, ${image.url}?w=${image.fixed.width *
        2}&lossless=0&q=${quality} 2x, ${image.url}?w=${image.fixed.width *
        2.5}&lossless=0&q=${quality} 2.5x, ${image.url}?w=${image.fixed.width *
        3}&lossless=0&q=${quality} 3x`,

      srcWebp: `${image.url}?w=${
        image.fixed.width
      }&fm=webp&lossless=0&q=${quality}`,
      srcSetWebp: `${image.url}?w=${
        image.fixed.width
      }&fm=webp&lossless=0&q=${quality} 1x, ${image.url}?w=${image.fixed.width *
        1.5}&fm=webp&lossless=0&q=${quality} 1.5x, ${image.url}?w=${image.fixed
        .width * 2}&fm=webp&lossless=0&q=${quality} 2x, ${image.url}?w=${image
        .fixed.width * 2.5}&fm=webp&lossless=0&q=${quality} 2.5x, ${
        image.url
      }?w=${image.fixed.width * 3}&fm=webp&lossless=0&q=${quality} 3x`,
    }
  }

  return image.fluid ? fluid() : fixed()
}
