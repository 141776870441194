import { graphql, StaticQuery } from 'gatsby';
import React from 'react';

import Links from './footerLinks';
import Section from './section';
import sectionStyles from './section.module.scss';
import styles from './sectionFooter.module.scss';

const SectionFooter = ({ logo, copyright, links, background }) => (
  <Section
    contentAlignment="footer"
    isDark={true}
    background={background}
    mainContent={() => <Links data={links} />}
    headerContent={() => <img className={styles.logo} src={logo.url} />}
    footerContent={() => (
      <div
        className={`${sectionStyles['section__text']}`}
        dangerouslySetInnerHTML={{
          __html: copyright,
        }}
      />
    )}
  />
)

export default props => (
  <StaticQuery
    query={graphql`
      query {
        datoCmsPageFooter {
          logo {
            url
          }
          background {
            fluid(maxWidth: 2560) {
              ...GatsbyDatoCmsFluid
            }
            alt
            title
            url
          }
          copyright
          links {
            title
            url
          }
        }
      }
    `}
    render={data => <SectionFooter {...data.datoCmsPageFooter} {...props} />}
  />
)
