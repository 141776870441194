import React from 'react'
import styles from './section.module.scss'
import Button from './button'
import Container from './container'
import Img from 'gatsby-image'
import gatsbyImageData from './gatsbyImageData'

export default ({
  background,
  headerContent,
  mainContent,
  footerContent,
  afterSectionContent,
  contentAlignment,
  isDark,
  container = true,
  ...props
}) => {
  const capitaliseFirstLetter = string =>
    string[0].toUpperCase() + string.slice(1)
  const sectionVariant = contentAlignment
    ? styles[`section${capitaliseFirstLetter(contentAlignment)}`]
    : ''
  const sectionBackground = isDark ? styles['sectionDark'] : ''
  const backgroundModifier = props.backgroundVariant
    ? styles[
        `section__background${capitaliseFirstLetter(props.backgroundVariant)}`
      ]
    : ''

  const Header = () =>
    props.title ? (
      <h2
        className={styles['section__title']}
        dangerouslySetInnerHTML={{
          __html: props.title,
        }}
      />
    ) : headerContent ? (
      headerContent()
    ) : null

  const Content = () =>
    props.text ? (
      <div
        className={styles['section__text']}
        dangerouslySetInnerHTML={{
          __html: props.text.replace('&nbsp; ', '&nbsp;'),
        }}
      />
    ) : mainContent ? (
      mainContent()
    ) : null

  const Footer = () => (
    <>
      {props.buttonLink && props.buttonText ? (
        <Button href={props.buttonLink} variant="outline_secondary">
          {props.buttonText}
        </Button>
      ) : null}

      {footerContent ? footerContent() : null}

      {props.image ? (
        <Img
          className={`${styles['section__image']}`}
          fluid={{
            ...gatsbyImageData({
              image: props.image,
              sizes: {
                mobile: `100vw`,
                tablet: `calc(100vw - ${20 / 16}em)`,
                desktop: `${945 / 16}em`,
              },
              srcSet: [
                '360',
                '480',
                '640',
                '768',
                '800',
                '945',
                '1500',
                '1900',
              ],
            }),
          }}
          alt={
            props.image.alt ||
            (props.title && props.title.replace(/<\/?[^>]+(>|$)/g, '')) ||
            'Gymradio'
          }
          imgStyle={{ objectPosition: '', objectFit: '' }}
          style={{ position: '' }}
        />
      ) : null}
    </>
  )

  const SectionSkeleton = () => (
    <div className={styles['section__inner']}>
      <header className={styles['section__header']}>
        <Header />
      </header>

      <div className={styles['section__content']}>
        <Content />
      </div>

      <footer className={styles['section__footer']}>
        <Footer />
      </footer>
    </div>
  )

  return (
    <section
      className={`${styles.section} ${sectionVariant} ${sectionBackground}`}
    >
      {background && (
        <Img
          className={`${styles['section__background']} ${backgroundModifier}`}
          fluid={{
            ...gatsbyImageData({
              image: background,
              sizes: {
                mobile: `calc(100vw * ${
                  props.backgroundVariant &&
                  props.backgroundVariant === 'absolute'
                    ? 0.5
                    : 0.9
                } * ${background.fluid.aspectRatio})`,
                tablet: `calc(100vw * ${
                  props.backgroundVariant &&
                  props.backgroundVariant === 'absolute'
                    ? 0.5
                    : 0.9
                } * ${background.fluid.aspectRatio})`,
                desktop: `${2560 / 16}em`,
              },
              srcSet: [
                '600',
                '800',
                '1000',
                '1300',
                '1600',
                '1800',
                '2100',
                '2560',
              ],
            }),
          }}
          alt={
            background.alt ||
            (props.title && props.title.replace(/<\/?[^>]+(>|$)/g, '')) ||
            ''
          }
          imgStyle={{ objectPosition: '', objectFit: '' }}
          style={{ position: '' }}
          Tag="figure"
        />
      )}

      {container ? (
        <Container>
          <SectionSkeleton />
        </Container>
      ) : (
        <SectionSkeleton />
      )}

      {afterSectionContent ? afterSectionContent() : null}
    </section>
  )
}
