import React from 'react'

import '../global-styles/reset.scss'
import '../global-styles/fonts.scss'
import '../global-styles/typography.scss'

export default class Layout extends React.Component {
  setupIntercom() {
    const getUserData =
      window.sessionStorage.getItem('user') &&
      JSON.parse(window.sessionStorage.getItem('user'))

    if (getUserData) {
      const settings = {
        app_id: 'pivanks0',
        user_id: getUserData.userid,
        email: getUserData.email,
      }

      window.Intercom && window.Intercom('boot', settings)
    }
  }

  componentDidMount() {
    this.setupIntercom()
  }

  render() {
    return <div>{this.props.children}</div>
  }
}
